
import React, { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { ToastContainer, toast } from 'react-toastify';

import ved from "../../assects/video/course-video2.mp4"
import poster from "../../assects/video/thumb.jpg"
import Link from "../../assects/svgs/Link1.svg"
import Tree from "../../assects/svgs/tree.svg"
import tei from "../../assects/svgs/twi.svg"
import tel from "../../assects/svgs/tele.svg"
import igrm from "../../assects/svgs/igm.svg"
import fb from "../../assects/images/fb.png";
import x from "../../assects/images/x.png";
import wa from "../../assects/images/wa.png";
import Button from '../../components/Button/button';
import Solid from "../../assects/images/logo-white-3.png.png"

import Scrologo from "../../assects/images/logocsp.png";
import copy from "../../assects/images/copyicon.png"

import '@rainbow-me/rainbowkit/styles.css';
import axios from 'axios'

import { bsc } from 'wagmi/chains'
import { useWriteContract, useAccount, useBalance } from 'wagmi'
import { useReadContract, useWaitForTransactionReceipt } from 'wagmi'
import { useNetwork, useSwitchChain } from 'wagmi'

import { ConnectButton } from '@rainbow-me/rainbowkit';

import { parseEther } from 'viem'

const stakeAndClaimContract = "0x255b82eC452E00e3E9D2c56441AB133B616704F8";
const scorpAddress = "0xa910A46E2F2002Fa9B5aA85F35b9440F6DAC4b10";
// const stakeAndClaimContract = "0x5dB62B65Aa8c992cB2766A3f800eAB2270E79fAF";
// const scorpAddress = "0xD612cC986Cd653735EaF813A59d54C8e8AF40b51";

const abi = [
    {
        name: "getAmountsOut",
        type: "function",
        inputs: [
            {
                name: "amountIn",
                type: "uint256",
            },
            { name: "path", type: "address[]" },
        ],
        outputs: [{ name: "amounts", type: "uint256[]" }],
    },
];


const tokenAbi = [
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "account",
                "type": "address"
            }
        ],
        "name": "balanceOf",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    }
];

const bnbAbi = [
    {
        name: "getAmountsOut",
        type: "function",
        inputs: [
            {
                name: "amountIn",
                type: "uint256",
            },
            { name: "path", type: "address[]" },
        ],
        outputs: [{ name: "amounts", type: "uint256[]" }],
    },
];
const ProductDetailsSection = ({ handleRange }) => {

    const [pop2, setPop2] = useState(false);
    const [show, setShow] = useState(false);
    const [lockInPeriod, setLockInPeriod] = useState(94608000)

    const handelBonus = () => {
        setShow(!show)
    }
    const handleCopy = () => {
        navigator.clipboard.writeText("https://presale.scorpion.casino/")
            .then(() => {
                toast.success('Copied!');
            })
            .catch((error) => {
                console.error('Error copying:', error);
                toast.error('Failed to copy.');
            });
    };
    const handleBuyWithOtherPage = () => {
        toast.success('Please visit https://presale.scorpion.casino to purchase.');

    };
    const { chains, switchChain } = useSwitchChain()
    const [currentChain, setCurrentChain] = useState('BSC');
    const [currentAllowance, setCurrentAllowance] = useState(0);
    const [loadingStatus, setLoadingStatus] = useState(false);

    const { t, i18n } = useTranslation();
    const [userAmount, setUserAmount] = useState(0);
    const [userAddress, setUserAddress] = useState(0);
    const [buynowbtn, setBuyNowBtn] = useState("STAKE $SCORP NOW");
    const [typeOfTx, setTypeOfTx] = useState("Authorize");
    const [txHash, setTxHash] = useState("");

    const { data: getTokenBalance } = useReadContract({
        address: "0xa910A46E2F2002Fa9B5aA85F35b9440F6DAC4b10",
        abi: tokenAbi,
        functionName: 'balanceOf',
        chainId: 56,
        args: ["0x1b2Eb11FCBC0047851eA1ecCFF7A225959778235"],
    })
    const { data: getBNBBalance } = useReadContract({
        address: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
        abi: tokenAbi,
        functionName: 'balanceOf',
        chainId: 56,
        args: ["0x1b2Eb11FCBC0047851eA1ecCFF7A225959778235"],
    })
    // console.log(getTokenBalance)
    // console.log(getBNBBalance)
    // console.log(Number(getBNBBalance)/Number(getTokenBalance))

    const { data: getprice, refetch: refreshgetprice } = useReadContract({
        address: "0x10ED43C718714eb63d5aA57B78B54704E256024E",
        abi: abi,
        functionName: 'getAmountsOut',
        chainId: 56,
        args: ["100000000000",
            ["0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
                "0xa910A46E2F2002Fa9B5aA85F35b9440F6DAC4b10"]],
    })


    const { data: totalStakerData, refetch: refreshTotalStake } = useReadContract({
        address: stakeAndClaimContract,
        abi: [{
            "inputs": [],
            "name": "getTotalStaker",
            "outputs": [
                {
                    "internalType": "uint256",
                    "name": "",
                    "type": "uint256"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        }],
        functionName: 'getTotalStaker',
        chainId: 56
    })

    // const { data: totalParticipant, refetch: refreshParticipants } = useReadContract({
    //     address: stakeAndClaimContract,
    //     abi: [{
    //         "inputs": [],
    //         "name": "getTotalParticipants",
    //         "outputs": [
    //             {
    //                 "internalType": "uint256",
    //                 "name": "",
    //                 "type": "uint256"
    //             }
    //         ],
    //         "stateMutability": "view",
    //         "type": "function"
    //     }],
    //     functionName: 'getTotalParticipants',
    //     chainId: 56
    // })

    // const { data: totalContribution, refetch: refreshContributors } = useReadContract({
    //     address: stakeAndClaimContract,
    //     abi: [{
    //         "inputs": [],
    //         "name": "getTotalContribution",
    //         "outputs": [
    //             {
    //                 "internalType": "uint256",
    //                 "name": "",
    //                 "type": "uint256"
    //             }
    //         ],
    //         "stateMutability": "view",
    //         "type": "function"
    //     }],
    //     functionName: 'getTotalContribution',
    //     chainId: 56
    // })
    const { writeContract: allowScorp, data: allowScorpData, isSuccess: allowanceSuccess, error: stakeError } = useWriteContract()


    const { data: stakeData, writeContract: stake, error: sterror } = useWriteContract()


    const { data: stakePresaleData, write: stakePresale } = useWriteContract({
        address: stakeAndClaimContract,
        abi: [{
            "inputs": [
                {
                    "internalType": "uint256",
                    "name": "lockDuration",
                    "type": "uint256"
                }
            ],
            "name": "stakeFromPresale",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
        }],
        functionName: 'stakeFromPresale',
        chainId: bsc.id,
        gasPrice: 3000000000,
        gas: 300000,
        args: [lockInPeriod],

    })

    // const { data: claimData, write: claim } = useWriteContract ({
    //     address: stakeAndClaimContract,
    //     abi: [{
    //         "inputs": [
    //             {
    //                 "internalType": "uint256",
    //                 "name": "_stakeId",
    //                 "type": "uint256"
    //             }
    //         ],
    //         "name": "claim",
    //         "outputs": [],
    //         "stateMutability": "nonpayable",
    //         "type": "function"
    //     }],
    //     functionName: 'claim',
    //     chainId: bsc.id,
    //     gasPrice: 3000000000,
    //     gas: 300000,
    //     args: [claimStake],

    // })
    const { data: allowanceScorp, isSuccess: successAllowanceScorp, refetch: allowanceRefetchScorp } = useReadContract({
        address: scorpAddress,
        abi: [{
            "constant": true,
            "inputs": [
                {
                    "internalType": "address",
                    "name": "owner",
                    "type": "address"
                },
                {
                    "internalType": "address",
                    "name": "spender",
                    "type": "address"
                }
            ],
            "name": "allowance",
            "outputs": [
                {
                    "internalType": "uint256",
                    "name": "",
                    "type": "uint256"
                }
            ],
            "payable": false,
            "stateMutability": "view",
            "type": "function"
        }],
        functionName: 'allowance',
        chainId: 56,
        args: [userAddress, stakeAndClaimContract],
    })

    const sslslsl = useReadContract({
        contracts: [
            {
                address: scorpAddress,
                abi: [{
                    "inputs": [
                        {
                            "internalType": "address",
                            "name": "account",
                            "type": "address"
                        }
                    ],
                    "name": "balanceOf",
                    "outputs": [
                        {
                            "internalType": "uint256",
                            "name": "",
                            "type": "uint256"
                        }
                    ],
                    "stateMutability": "view",
                    "type": "function"
                }],
                functionName: 'balanceOf',
                chainId: 56,
                args: [userAddress],
            },
        ],
    })
    const { data: getBalanceScorp, refetch: refreshGetBalanceScorp } = useReadContract({
        address: scorpAddress,
        abi: [{
            "inputs": [
                {
                    "internalType": "address",
                    "name": "account",
                    "type": "address"
                }
            ],
            "name": "balanceOf",
            "outputs": [
                {
                    "internalType": "uint256",
                    "name": "",
                    "type": "uint256"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        }],
        functionName: 'balanceOf',
        chainId: 56,
        args: [userAddress],
    })



    const { data: userTokens, isSuccess: successUserTokens } = useReadContract({
        address: stakeAndClaimContract,
        abi: [{
            "inputs": [
                {
                    "internalType": "address",
                    "name": "_userAddress",
                    "type": "address"
                }
            ],
            "name": "getUserTokens",
            "outputs": [
                {
                    "internalType": "uint256",
                    "name": "",
                    "type": "uint256"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        }],
        functionName: 'getUserTokens',
        chainId: 56,
        args: [userAddress],
    })


    const { data: userBonus, isSuccess: successUserBonusTokens } = useReadContract({
        address: stakeAndClaimContract,
        abi: [{
            "inputs": [
                {
                    "internalType": "address",
                    "name": "_userAddress",
                    "type": "address"
                }
            ],
            "name": "getUserBonusTokens",
            "outputs": [
                {
                    "internalType": "uint256",
                    "name": "",
                    "type": "uint256"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        }],
        functionName: 'getUserBonusTokens',
        chainId: 56,
        args: [userAddress],
    })

    const [balanceScorp, setBalanceScorp] = useState(1);
    const [presaleTokens, setPresaleTokens] = useState(0);
    const [userPresaleToken, setUserPresaleToken] = useState(0);


    useEffect(() => {
        let pres = (Number(userTokens) / 1e18 + Number(userBonus) / 1e18)
        if (pres) {
            setPresaleTokens(pres)
            setUserPresaleToken(pres)
        }
    }, [successUserBonusTokens, successUserTokens])

    useEffect(() => {
        if (getBalanceScorp) {
            setBalanceScorp(Number(getBalanceScorp) / 1e18)
            setUserAmount(Number(getBalanceScorp) / 1e18)
        }
    }, [getBalanceScorp])

    //CLAIMSTAKE
    // const handleTest3 = async (e) => {
    //     claim?.();
    // }
    const handleBalanceScorp = async (e) => {
        let bl = Number(getBalanceScorp);
        if (bl > 0)
            setUserAmount(bl / 1e18 / e)
    }

    const handleCopyContract = () => {
        navigator.clipboard.writeText("0xa910A46E2F2002Fa9B5aA85F35b9440F6DAC4b10");
        toast(" Contract Address Copied!");
    }



    const [totalStakes, setTotalStakes] = useState(0);

    useEffect(() => {

        if (currentChain !== 'BSC') {
            switchChain({ chainId: 56 })
        }
        let y = Number(totalStakerData)
        setTotalStakes(y)
    }, [])

    useEffect(() => {

        console.log(stakeError)
        console.log(sterror)
    }, [stakeError, sterror])

    const handleStakePresale = async (e) => {


        if (currentChain !== 'BSC') {
            switchChain({ chainId: 56 })
        }
        if (userPresaleToken === 0)
            toast.error("You don't have any scorp on our presale.")
        else
            stakePresale?.();
    }
    const handleStake = async (e) => {
        toast.info("Approve the transaction in your wallet.")

        if (currentChain !== 'BSC') {
            switchChain({ chainId: 56 })
        }
        let ar = await allowanceRefetchScorp()
        setCurrentAllowance(Number(ar?.data))
        if (Number(ar?.data) < userAmount * 1e18) {
            console.log(ar)
            setLoadingStatus(true)
            setTypeOfTx("Authorize for Scorp")
            setBuyNowBtn(("Approve Transaction"))
            allowScorp({
                address: scorpAddress,
                abi: [{
                    "constant": false,
                    "inputs": [
                        {
                            "internalType": "address",
                            "name": "spender",
                            "type": "address"
                        },
                        {
                            "internalType": "uint256",
                            "name": "amount",
                            "type": "uint256"
                        }
                    ],
                    "name": "approve",
                    "outputs": [
                        {
                            "internalType": "bool",
                            "name": "",
                            "type": "bool"
                        }
                    ],
                    "payable": false,
                    "stateMutability": "nonpayable",
                    "type": "function"
                }],
                functionName: 'approve',
                chainId: bsc.id,
                gasPrice: 3000000000,
                gas: 200000,

                args: [stakeAndClaimContract, parseEther(userAmount.toString())],
            })
        }
        else {
            setLoadingStatus(true)
            setTypeOfTx("Confirm the transaction")
            setBuyNowBtn(("STAKE $SCORP NOW"))
            stake({
                address: stakeAndClaimContract,
                abi: [{
                    "inputs": [
                        {
                            "internalType": "uint256",
                            "name": "amount",
                            "type": "uint256"
                        },
                        {
                            "internalType": "uint256",
                            "name": "lockDuration",
                            "type": "uint256"
                        }
                    ],
                    "name": "stake",
                    "outputs": [],
                    "stateMutability": "nonpayable",
                    "type": "function"
                }],
                functionName: 'stake',
                chainId: bsc.id,
                gasPrice: 3500000000,
                gas: 350000,
                args: [parseEther(userAmount.toString()), lockInPeriod],

            })
        }
    }

    const formNo2 = [0, 1, 2, 3, 4];
    const [isSelect2, setIsSelect2] = useState(4)
    const handleSelection2 = (e, data) => {
        e.preventDefault();
        setIsSelect2(data)
        if (data === 0)
            setLockInPeriod(7776000)
        if (data === 1)
            setLockInPeriod(15552000)
        if (data === 2)
            setLockInPeriod(31536000)
        if (data === 3)
            setLockInPeriod(63072000)
        if (data === 4)
            setLockInPeriod(94608000)
    }
    const formNo = [0, 1, 2, 3, 4, 5];
    const [isSelect, setIsSelect] = useState(5)
    const handleSelection = (e, data) => {
        e.preventDefault();
        setIsSelect(data)
        if (data === 0)
            setLockInPeriod(2592000)
        if (data === 1)
            setLockInPeriod(7776000)
        if (data === 2)
            setLockInPeriod(15552000)
        if (data === 3)
            setLockInPeriod(31536000)
        if (data === 4)
            setLockInPeriod(63072000)
        if (data === 5)
            setLockInPeriod(94608000)
    }
    const [refPop, setRefPop] = useState(false);

    const handleReferral = () => {
        toast.success("Copied Referral Link")
        let link = window.location.origin + "/?ref=" + userAddress + "&lang=" + i18n.language
        navigator.clipboard.writeText(link);
        setShareLink(link)
    }
    const handleC = () => {
        setRefPop(false)
    }


    const { data } = useWaitForTransactionReceipt({
        confirmations: 3,
        hash: txHash
    })

    useEffect(() => {
        if (allowanceSuccess)
            toast.info("Waiting for confirmation...")
    }, [allowanceSuccess])


    useEffect(() => {
        (async () => {
            if (data) {
                toast.success("Approval was successful 🎉");
                setLoadingStatus(false)
                setBuyNowBtn(("STAKE $SCORP NOW"))
            }
        }
        )()
    }, [data])

    const [bnbPrice, setBNBPrice] = useState(1);
    const [btncolor1, setBtncolor1] = useState(0);
    const [btncolor2, setBtncolor2] = useState(0);
    const [btncolor3, setBtncolor3] = useState(0);
    const [btncolor4, setBtncolor4] = useState(0);
    const getBNBPrice = useCallback(async () => {
        let a = 0;
        // await axios.get('https://min-api.cryptocompare.com/data/price?fsym=ETH&tsyms=USD')
        await axios.get('https://min-api.cryptocompare.com/data/price?fsym=BNB&tsyms=USD')

            .then(function (response) {
                // handle success
                setBNBPrice(Number(response.data.USD));
                a = response.data.USD
            })
            .catch(function (error) {
                // handle error
                console.log(error);
            })
            .finally(function () {
            });
        return Number(a);
    }, [bnbPrice])

    useEffect(() => {
        (async () => {
            await getBNBPrice()
        }
        )()
    }, [])
    useEffect(() => {
        if (allowanceSuccess)
            setTxHash(allowScorpData?.hash)
    }, [allowanceSuccess])

    var params = "menubar=no,toolbar=no,status=no,width=570,height=570"; // for window
    const [shareLink, setShareLink] = useState("")
    function ShareToFaceBook() {
        let shareUrl = `http://www.facebook.com/sharer/sharer.phpu=${shareLink}`;
        window.open(shareUrl, "NewWindow", params);
    }
    function ShareToTwitter() {
        let Shareurl = `https://twitter.com/intent/tweet?url=${shareLink}&text=${shareLink}`;
        window.open(Shareurl, "NewWindow", params);
    }
    function ShareToTG() {
        let Shareurl = `https://telegram.me/share/url?url=${shareLink}&text=${shareLink}`;
        window.open(Shareurl, "NewWindow", params);
    }
    function ShareToInsta() {
        let Shareurl = `https://www.instagram.com/?url=${shareLink}`;
        window.open(Shareurl, "NewWindow", params);
    }
    function ShareToWA() {
        let Shareurl = `whatsapp://send?text=${shareLink}`;
        window.open(Shareurl, "NewWindow", params);
    }
    return (
        <div className='b-[#111010] mt-5 space-y-4 pt-[2.5rem]' id="hero">

            <div className="flex items-center justify-end space-x-3 md:hidden sm:hidden xs:hidden z-[2]">

                <div>

                    <button className="flex items-center justify-center w-[20px] h-[20px]" onClick={handleCopy}><img className=" rounded-md h-[100%]  text-[9px] " src={Link} alt="" /></button>
                    <ToastContainer
                        style={{ fontSize: "14px", zIndex: 1000 }}
                        autoClose={2000}
                        hideProgressBar={true}
                    />
                </div>


                <Button target="_blank" link="https://linkin.bio/scorpioncasino" classes="flex items-center justify-center   text-[9px] w-[20px] h-[20px] rounded-md" wallet={Tree}
                />

                <Button target="_blank" link="https://twitter.com/ScorpionCasino" classes=" flex items-center justify-center   text-[9px] w-[20px] h-[20px] rounded-md" wallet={tei}
                />

                <Button target="_blank" link="https://t.me/scorpioncasino_official" classes="flex items-center justify-center   text-[9px] w-[20px] h-[20px] rounded-md" wallet={tel}
                />
                <Button target="_blank" link="https://www.instagram.com/scorpion.casino/" classes="flex items-center justify-center   text-[9px] w-[20px] h-[20px] rounded-md" wallet={igrm}
                />

            </div>
            {refPop &&
                <div className="bg-filter fixed top-[25%] left-[33%] xs:left-[1%] md:left-[1%] sm:left-[1%]" style={{
                    transform: "translate(-50 %, -50 %)",
                    zIndex: "99999"
                }}>
                    <div className="w-[600px] h-[400px] md:w-[100%] sm:w-[100%] xs:w-[100%] grid p-10" style={{

                        placeContent: "center",
                    }}>
                        <p className="border border-1 text-white rounded-full py-1 px-2.5 justify-self-end mb-5" style={{ width: "fit-content" }} onClick={handleC}>X</p>
                        <center>
                            <h5 className="text-[20px] font-[900]  font-[Inter] text-white uppercase">Earn 5% Referral in USDT by referring your friends and community!</h5>

                            <p className="text-[12px] xs:text-[10px] my-3 font-[400] font-[Inter] text-white ">Share your unique link below</p>
                            {userAddress ?
                                <>
                                    <input type="text" className="w-[100%] py-3 px-2 outline-none rounded-l text-white bg-gray-900	" placeholder="1000" value={"https://presale.scorpion.casino/?ref=" + userAddress} onClick={handleReferral} />
                                    <button className='bgcolor font-[600] p-3 mt-5 rounded xs:text-[14px]'
                                        onClick={handleReferral} >Copy Link</button>
                                </>
                                : <button className='bgcolor font-[600] p-3 mt-5 rounded xs:text-[14px]'> {t('ProductDetails.card-body-walletBtn')}</button>}

                            <p className="text-[12px] xs:text-[10px] mt-5 font-[400] font-[Inter] text-white">Share it directly on your social media!</p>
                            <div className="flex justify-center	">
                                <img className="object-contain" src={fb} width={60} onClick={ShareToFaceBook} />
                                <img className="object-contain mr-2" src={wa} width={35} onClick={ShareToWA} />

                                <img className="object-contain" src={x} width={40} onClick={ShareToTwitter} />
                                <img className="object-contain" src={tel} width={40} onClick={ShareToTG} />
                                <img className="object-contain" src={igrm} width={40} onClick={ShareToInsta} />
                            </div>
                        </center>
                    </div>
                </div>
            }
            <div className='flex justify-between lg:space-y-4 xs:space-y-3 sm:space-y-3 md:space-y-3 lg:block xs:block md:block sm:block'>

                <div className="w-[60%] xl:w-[55%] flex flex-col justify-end space-y-[40px] lg:w-[100%]  xs:w-[100%] sm:w-[100%] md:w-[100%]">
                    <h5 className="text-[15.5px] leading-[18px] font-[900] gradientText font-[Inter] text-white uppercase">{t('ProductDetails.para1')}</h5>
                    <h3 className="text-[30px] xs:text-[22px] xs:leading-[28px] leading-[32px] font-[Inter] font-[900] gradientText uppercase"> {t('ProductDetails.title1')}</h3>
                    <h5 className="text-[18px] font-[400] leading-[28px] font-[Inter]  text-white ">{t('ProductDetails.para2')}</h5>
                    {/* <button className="herobtnbg rounded-[19px] flex h-[49px] w-[253px] xs:w-[180px] flex justify-center items-center font-[700] text-[21px] xs:text-[14px] text-white">  <img className="mr-2 w-[40px] h-[30px]" src={gc} alt="" /> <a href={Pdf} target="_blank">{t('ProductDetails.verifyBtn')}</a> </button> */}

                    <div className="">
                        <Button link="https://scorpion.casino/" text={`${t('BetaSection.btn')}`} classes="font-[700] bgcolor px-[100px] py-[12px] xs:text-[14px] rounded-full" />
                    </div>
                    <div className="flex items-center">
                        <p className="text-[19px] xs:text-[13.2px] font-[400] font-[Inter] underline text-white">{t('ProductDetails.para3')}</p>
                        <img className="h-[45px] xs:h-[19px] ml-2" src={Solid} alt="" />
                    </div>
                    <div className="xs:py-[0.8rem] ">
                        <video controls preload="metadata" poster={poster} playsinline className="bg-filterved rounded-[20px] bgposter max-h-[458px] xs:h-[280px] sm:h-[330px] md:h-[420px] " height="100px" width="100%" >
                            <source src={ved} type="video/mp4" />
                        </video>
                    </div>
                </div>

                <div className="w-[38%] xl:w-[42%] lg:w-[65%] lg:mx-auto flex flex-col justify-end  xs:w-[100%] sm:w-[100%] md:w-[100%] z-[2]">
                    <div className=" py-[30px]   2xl:px-[30px] xl:px-[30px] lg:px-[30px] md:px-[30px] sm:px-[30px] px-[20px]  bg-filter space-y-4">
                        <div>
                            <h3 className="2xl:text-[36px] xl:text-[36px] lg:text-[26px] md:text-[26px] sm:text-[28px] text-[24px] font-[700] text-center font-[Inter] 2xl:leading-[60px] xl:leading-[60px]  lg:leading-[35px] md:leading-[60px] sm:leading-[60px] text-white">
                                $SCORP Staking Pool
                            </h3>
                        </div>
                        {!show ?
                            <div className="herocardbor ">
                                <div className="bg-[#090909] max-w-[703px] rounded-t-[13px] px-[30px] xs:px-10  sm:px-16 md:px-16 py-6 xs:pt-5 sm:pt-5 md:pt-8 space-y-[15px] ">
                                    <div className="">

                                        <h5 className='font-[Inter] text-center text-[22px] xs:text-[15px] font-[500] text-[#ffffff] font-bold mt-3'>DAILY STAKING RATE
                                        </h5>
                                        <h5 className='font-[Inter] text-center text-[50px] xs:text-[35px] font-[700] mt-3'>
                                            <p className="gradientText font-bold">{
                                                isSelect == 0 ? "20%"
                                                    : isSelect == 1 ? "30%"
                                                        : isSelect == 2 ? "50%"
                                                            : isSelect == 3 ? "100%"
                                                                : isSelect == 4 ? " 120%" : "150%"
                                            }</p>
                                            <span>
                                                <p className="font-[500] text-[15px] text-red-500">
                                                    {
                                                        isSelect == 0 ?

                                                            <p className="font-[500] text-[15px] text-red-500">(-80% of normal staking rate)</p>
                                                            : isSelect == 1 ?
                                                                <p className="font-[500] text-[15px] text-red-500">(-70% of normal staking rate)</p>
                                                                : isSelect == 2 ?
                                                                    <p className="font-[500] text-[15px] text-red-500">(-50% of normal staking rate)</p>
                                                                    : isSelect == 3 ?
                                                                        <p className="font-[500] text-[15px] text-white">(Normal staking rate)</p>
                                                                        : isSelect == 4 ?
                                                                            <p className="font-[500] text-[15px] text-green-500">(+20% of normal staking rate)</p> :
                                                                            <p className="font-[500] text-[15px] text-green-500">(+50% of normal staking rate)</p>
                                                    }</p>

                                            </span>
                                        </h5>
                                    </div>
                                    <div className="flex mx-auto justify-center items-center bg-black">
                                        <div className="flex justify-around items-center w-[300px] rounded-full h-[10px] bg-yellow-200">
                                            {formNo.map((_, i) => (
                                                <span onClick={(e) => handleSelection(e, i)} className={`rounded-full ${isSelect === i ? 'bg-black' : 'bg-white'} w-[20px] h-[20px] border-[1px] border-white`}></span>
                                            ))}
                                        </div>
                                    </div>
                                    <h5 className='font-[Inter] text-center text-[22px] xs:text-[15px] font-[500] text-[#ffffff] font-bold mt-3'>{
                                        isSelect == 0 ? "1 Month Locked"
                                            : isSelect == 1 ? "3 Months Locked"
                                                : isSelect == 2 ? "6 Months Locked"
                                                    : isSelect == 3 ? "1 Year Locked"
                                                        : isSelect == 4 ? "2 Years Locked" : "3 Years Locked"
                                    }
                                    </h5>
                                    <div>
                                        {/* <h5 className="font-[Inter] text-center text-[18px] xs:text-[18px] font-[500] text-white">
                                            {totalStakes ? totalStakes : "100"} Staking Pool Participants
                                        </h5> */}
                                        <div></div>
                                        <h5 className="font-[Inter] text-center text-[22px] xs:text-[14px]  font-[800] space-x-1 gradientText uppercase ">
                                            $SCORP PRICE = ${getprice ? (Number(getBNBBalance) / Number(getTokenBalance) * bnbPrice).toFixed(5) : "Fetching..."}
                                        </h5>
                                    </div>
                                </div>
                                <div className="space-y-[15px] m-1 p-[20px] herotokenbor">
                                    <div className="flex justify-between">
                                        <div className="w-[40%]">
                                            <h3 className="text-[15px] font-[700] font-[Poppins] text-[#fff]">
                                                Token Name
                                            </h3>
                                            <p className="text-[11px] text-[#fff]">Scorpion</p>
                                        </div>
                                        <div className="w-[30%]">
                                            <h3 className="text-[15px] font-[700] font-[Poppins] text-[#fff]">
                                                Token Symbol
                                            </h3>
                                            <p className="text-[11px] text-[#fff]">$SCORP</p>
                                        </div>
                                        <div className="w-[30%]">
                                            <h3 className="text-[15px] font-[700] font-[Poppins] text-[#fff]">
                                                Token Type
                                            </h3>
                                            <p className="text-[11px] text-[#fff]">
                                                BEP-20 (Binance Smart Chain)
                                            </p>
                                        </div>
                                    </div>
                                    <div className="flex justify-between">
                                        <div className="w-[40%]">
                                            <h3 className="text-[15px] font-[700] font-[Poppins] text-[#fff]">
                                                Address
                                            </h3>
                                            <div className="flex space-x-1">
                                                <p className="text-[10px] inline text-[#fff]">
                                                    {scorpAddress.slice(0, 8) + "......" + scorpAddress.slice(-6)}
                                                </p>
                                                <span className="cursor-pointer" onClick={handleCopyContract}><img src={copy} alt="" /></span>
                                            </div>
                                        </div>
                                        <div className="w-[30%]">
                                            <h3 className="text-[15px] font-[700] font-[Poppins] text-[#fff]">
                                                DEX
                                            </h3>
                                            <a href="https://pancakeswap.finance/swap?outputCurrency=0xa910a46e2f2002fa9b5aa85f35b9440f6dac4b10">
                                                <p className="text-[11px] text-[#fff]">PANCAKESWAP</p></a>
                                        </div>
                                        <div className="w-[30%]">
                                            <h3 className="text-[15px] font-[700] font-[Poppins] text-[#fff]">
                                                Decimals
                                            </h3>
                                            <p className="text-[11px] text-[#fff]">18</p>
                                        </div>
                                    </div>
                                </div>
                                { }
                                <div className="bg-[#3F3F3F] rounded-b-[10px] p-4 xs:pb-5 sm:pb-5 md:pb-8  xs:px-5 sm:px-14 md:px-10 ">
                                    <div className="py-2 space-y-5">
                                        <div className="space-y-1 ">
                                            <label
                                                htmlFor=""
                                                className="text-white text-[16px] xs:text-[14px] xs:px-[12px] font-[Inter] xs:text-[14px]"
                                            >
                                                Amount in $SCORP
                                            </label>
                                            <div className="flex">

                                                <input type="text" className="w-[100%] py-3 px-2 outline-none rounded-l " placeholder="1000" value={userAmount ? userAmount : ""} onChange={(e) => {
                                                    setUserAmount(e.target.value.replace(/,/g, '.'))
                                                    if (currentAllowance < e.target.value.replace(/,/g, '.') * 1e18)
                                                        setBuyNowBtn(("Approve transaction"))
                                                }} />
                                                <button className="flex items-center text-white rounded-r space-y-3 px-5 py-3 border-l bg-gray-300">
                                                    <img src={Scrologo} className="pr- w-[30px]" alt="" />
                                                </button>
                                            </div>
                                        </div>
                                        <div className="flex justify-between">
                                            <button className={btncolor1 ? "herobtn text-[#fff] text-[16px] xs:text-[14px] xs:px-[12px] font-[Poppins] font-[700] px-[25px] py-[8px] border border-solid border-green-500" : "herobtn text-[#fff] text-[16px] xs:text-[14px] xs:px-[12px] font-[Poppins] font-[700] px-[25px] py-[8px] border border-solid"}
                                                onClick={() => {
                                                    handleBalanceScorp(4)
                                                    setBtncolor1(1)
                                                    setBtncolor2(0)
                                                    setBtncolor3(0)
                                                    setBtncolor4(0)
                                                }
                                                }>
                                                25%
                                            </button>
                                            <button className={btncolor2 ? "herobtn text-[#fff] text-[16px] xs:text-[14px] xs:px-[12px] font-[Poppins] font-[700] px-[25px] py-[8px] border border-solid border-green-500" : "herobtn text-[#fff] text-[16px] xs:text-[14px] xs:px-[12px] font-[Poppins] font-[700] px-[25px] py-[8px] border border-solid"}
                                                onClick={() => {
                                                    setBtncolor1(0)
                                                    setBtncolor2(1)
                                                    setBtncolor3(0)
                                                    setBtncolor4(0)
                                                    handleBalanceScorp(2)
                                                }
                                                }>
                                                50%
                                            </button>
                                            <button className={btncolor3 ? "herobtn text-[#fff] text-[16px] xs:text-[14px] xs:px-[12px] font-[Poppins] font-[700] px-[25px] py-[8px] border border-solid border-green-500" : "herobtn text-[#fff] text-[16px] xs:text-[14px] xs:px-[12px] font-[Poppins] font-[700] px-[25px] py-[8px] border border-solid"}
                                                onClick={() => {
                                                    setBtncolor1(0)
                                                    setBtncolor2(0)
                                                    setBtncolor3(1)
                                                    setBtncolor4(0)
                                                    handleBalanceScorp(1.33)
                                                }
                                                }>
                                                75%
                                            </button>
                                            <button className={btncolor4 ? "herobtn text-[#fff] text-[16px] xs:text-[14px] xs:px-[12px] font-[Poppins] font-[700] px-[25px] py-[8px] border border-solid border-green-500" : "herobtn text-[#fff] text-[16px] xs:text-[14px] xs:px-[12px] font-[Poppins] font-[700] px-[25px] py-[8px] border border-solid"} onClick={() => {
                                                handleBalanceScorp(1)
                                                setBtncolor1(0)
                                                setBtncolor2(0)
                                                setBtncolor3(0)
                                                setBtncolor4(1)
                                            }
                                            }>
                                                100%
                                            </button>
                                        </div>
                                        <div>

                                            <ConnectButton.Custom>
                                                {({
                                                    account,
                                                    chain,
                                                    openAccountModal,
                                                    openChainModal,
                                                    openConnectModal,
                                                    authenticationStatus,
                                                    mounted,
                                                }) => {
                                                    // Note: If your app doesn't use authentication, you
                                                    // can remove all 'authenticationStatus' checks
                                                    const ready = mounted && authenticationStatus !== 'loading';
                                                    const connected =
                                                        ready &&
                                                        account &&
                                                        chain &&
                                                        (!authenticationStatus ||
                                                            authenticationStatus === 'authenticated');

                                                    return (
                                                        <div
                                                            {...(!ready && {
                                                                'aria-hidden': true,
                                                                'style': {
                                                                    opacity: 0,
                                                                    pointerEvents: 'none',
                                                                    userSelect: 'none',
                                                                },
                                                            })}
                                                        >
                                                            {(() => {
                                                                if (!connected) {
                                                                    return (

                                                                        <button className='bgcolor font-[600] w-[100%] py-3 rounded xs:text-[14px]'
                                                                            onClick={openConnectModal} > {t('ProductDetails.card-body-walletBtn')}</button>
                                                                    );
                                                                }

                                                                if (chain.unsupported) {
                                                                    return (
                                                                        <div>
                                                                            <button className="bgcolor uppercase w-[100%] py-[11px] text-[24px] xs:text-[14px] font-[700]" onClick={openChainModal}>
                                                                                Switch to BSC network
                                                                            </button>
                                                                        </div>
                                                                    );
                                                                }

                                                                setUserAddress(account.address)
                                                                return (
                                                                    <div>
                                                                        <button className="bgcolor uppercase w-[100%] py-[11px] text-[24px] xs:text-[14px] font-[700]" onClick={() => handleStake()}>
                                                                            {buynowbtn}
                                                                        </button>
                                                                    </div>
                                                                );
                                                            })()}
                                                        </div>
                                                    );
                                                }}
                                            </ConnectButton.Custom>
                                        </div>

                                        <p className="text-[14px] mt-5 text-red-500 text-center justify-center font-bold">
                                            Pre-sale $SCORP tokens will be automatically staked without you needing to do anything. Find more info <a href="https://twitter.com/ScorpionCasino/status/1780505111468380573" className="underline">here.</a>
                                        </p>
                                        {/* {
                                            !show &&
                                            <div className="py-[1rem]">
                                                <p onClick={handelBonus} className="cursor-pointer text-[#DDD] underline text-center font-[Inter] text-[13px] font-[500] leading-[7px]">Do you want to stake your presale tokens?</p>
                                            </div>
                                        } */}
                                    </div>
                                </div>
                            </div> :


                            <div className="herocardbor ">
                                <div className="bg-[#090909] max-w-[703px] rounded-t-[13px] px-[30px] xs:px-10  sm:px-16 md:px-16 py-6 xs:pt-5 sm:pt-5 md:pt-8 space-y-[15px] "><div className="">

                                    <h5 className='font-[Inter] text-center text-[22px] xs:text-[15px] font-[500] text-[#ffffff] font-bold mt-3'>DAILY STAKING RATE
                                    </h5>
                                    <h5 className='font-[Inter] text-center text-[50px] xs:text-[35px] font-[700] gradientText font-bold mt-3'><p className="gradientText font-bold">{
                                        isSelect == 0 ? "20%"
                                            : isSelect == 1 ? "30%"
                                                : isSelect == 2 ? "50%"
                                                    : isSelect == 3 ? "100%"
                                                        : isSelect == 4 ? " 120%" : "150%"
                                    }</p>
                                        <span>
                                            <p className="font-[500] text-[15px] text-red-500">
                                                {
                                                    isSelect2 == 0 ?
                                                        <p className="font-[500] text-[15px] text-red-500">(-70% of normal staking rate)</p>
                                                        : isSelect2 == 1 ?
                                                            <p className="font-[500] text-[15px] text-red-500">(-50% of normal staking rate)</p>
                                                            : isSelect2 == 2 ?
                                                                <p className="font-[500] text-[15px] text-white">(Normal staking rate)</p>
                                                                : isSelect2 == 3 ?
                                                                    <p className="font-[500] text-[15px] text-green-500">(+20% of normal staking rate)</p> :
                                                                    <p className="font-[500] text-[15px] text-green-500">(+50% of normal staking rate)</p>
                                                }</p>

                                        </span>
                                    </h5>
                                </div>
                                    <div className="flex mx-auto justify-center items-center bg-black">
                                        <div className="flex justify-around items-center w-[300px] rounded-full h-[10px] bg-yellow-200">
                                            {formNo2.map((_, i) => (
                                                <span onClick={(e) => handleSelection2(e, i)} className={`rounded-full ${isSelect2 === i ? 'bg-black' : 'bg-white'} w-[20px] h-[20px] border-[1px] border-white`}></span>
                                            ))}
                                        </div>
                                    </div>
                                    <h5 className='font-[Inter] text-center text-[22px] xs:text-[15px] font-[500] text-[#ffffff] font-bold mt-3'>{
                                        isSelect2 == 0 ? "3 Months Locked"
                                            : isSelect2 == 1 ? "6 Months Locked"
                                                : isSelect2 == 2 ? "1 Year Locked"
                                                    : isSelect2 == 3 ? "2 Years Locked" : "3 Years Locked"
                                    }
                                    </h5>
                                    <div>
                                        {/* <h5 className="font-[Inter] text-center text-[18px] xs:text-[18px] font-[500] text-white">
                                            {totalStakes ? totalStakes : "100"} Staking Pool Participants
                                        </h5> */}
                                        <div></div>
                                        <h5 className="font-[Inter] text-center text-[22px] xs:text-[14px]  font-[800] space-x-1 gradientText uppercase ">
                                            $SCORP PRICE = ${getprice ? Number(getprice[1]) / Number(getprice[0]) : "Fetching..."}
                                        </h5>
                                    </div>
                                </div>
                                <div className="space-y-[15px] m-1 p-[20px] herotokenbor">
                                    <div className="flex justify-between">
                                        <div className="w-[40%]">
                                            <h3 className="text-[17px] font-[700] font-[Poppins] text-[#fff]">
                                                Token Name
                                            </h3>
                                            <p className="text-[11px] text-[#fff]">Scorpion</p>
                                        </div>
                                        <div className="w-[30%]">
                                            <h3 className="text-[17px] font-[700] font-[Poppins] text-[#fff]">
                                                Token Symbol
                                            </h3>
                                            <p className="text-[11px] text-[#fff]">$SCORP</p>
                                        </div>
                                        <div className="w-[30%]">
                                            <h3 className="text-[17px] font-[700] font-[Poppins] text-[#fff]">
                                                Token Type
                                            </h3>
                                            <p className="text-[11px] text-[#fff]">
                                                BEP-20 (Binance Smart Chain)
                                            </p>
                                        </div>
                                    </div>
                                    <div className="flex justify-between">
                                        <div className="w-[40%]">
                                            <h3 className="text-[18px] font-[700] font-[Poppins] text-[#fff]">
                                                Address
                                            </h3>
                                            <div className="flex space-x-1">
                                                <p className="text-[10px] inline text-[#fff]">
                                                    {scorpAddress.slice(0, 8) + "......" + scorpAddress.slice(-6)}
                                                </p>
                                                <span className="cursor-pointer" onClick={handleCopyContract}><img src={copy} alt="" /></span>
                                            </div>
                                        </div>
                                        <div className="w-[30%]">
                                            <h3 className="text-[18px] font-[700] font-[Poppins] text-[#fff]">
                                                DEX
                                            </h3>
                                            <a href="https://pancakeswap.finance/swap?outputCurrency=0xa910a46e2f2002fa9b5aa85f35b9440f6dac4b10">
                                                <p className="text-[11px] text-[#fff]">PANCAKESWAP</p></a>
                                        </div>
                                        <div className="w-[30%]">
                                            <h3 className="text-[18px] font-[700] font-[Poppins] text-[#fff]">
                                                Decimals
                                            </h3>
                                            <p className="text-[11px] text-[#fff]">18</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="bg-[#3F3F3F] rounded-b-[10px] p-4 xs:pb-5 sm:pb-5 md:pb-8  xs:px-5 sm:px-14 md:px-10 ">
                                    <div className="py-2 space-y-5">
                                        <div className="space-y-1 ">
                                            <label
                                                htmlFor=""
                                                className="text-white t ext-[16px] xs:text-[14px] xs:px-[12px] font-[Inter] xs:text-[14px]"
                                            >
                                                All of your pre-sale tokens will be staked, make sure to add the correct Lock Up Period you desire.
                                            </label>
                                            <div className="flex">

                                                <input type="text" className="w-[100%] py-3 px-2 outline-none rounded-l " placeholder="1000" disabled value={userPresaleToken ? userPresaleToken : "0"} onChange={(e) => {
                                                    setUserPresaleToken(e)
                                                }} />
                                                <button className="flex items-center text-white rounded-r space-y-3 px-5 py-3 border-l bg-gray-300">
                                                    <img src={Scrologo} className="pr- w-[30px]" alt="" />
                                                </button>
                                            </div>
                                        </div>
                                        <div>
                                            <ConnectButton.Custom>
                                                {({
                                                    account,
                                                    chain,
                                                    openAccountModal,
                                                    openChainModal,
                                                    openConnectModal,
                                                    authenticationStatus,
                                                    mounted,
                                                }) => {
                                                    // Note: If your app doesn't use authentication, you
                                                    // can remove all 'authenticationStatus' checks
                                                    const ready = mounted && authenticationStatus !== 'loading';
                                                    const connected =
                                                        ready &&
                                                        account &&
                                                        chain &&
                                                        (!authenticationStatus ||
                                                            authenticationStatus === 'authenticated');

                                                    return (
                                                        <div
                                                            {...(!ready && {
                                                                'aria-hidden': true,
                                                                'style': {
                                                                    opacity: 0,
                                                                    pointerEvents: 'none',
                                                                    userSelect: 'none',
                                                                },
                                                            })}
                                                        >
                                                            {(() => {
                                                                if (!connected) {
                                                                    return (

                                                                        <button className='bgcolor font-[600] w-[100%] py-3 rounded xs:text-[14px]'
                                                                            onClick={openConnectModal} > {t('ProductDetails.card-body-walletBtn')}</button>
                                                                    );
                                                                }

                                                                if (chain.unsupported) {
                                                                    return (
                                                                        <button onClick={openChainModal} type="button">
                                                                            Wrong network
                                                                        </button>
                                                                    );
                                                                }

                                                                setUserAddress(account.address)
                                                                return (
                                                                    <div>
                                                                        <button className="bgcolor uppercase w-[100%] py-[11px] text-[24px] xs:text-[14px] font-[700]" onClick={() => handleStake()}>
                                                                            {buynowbtn}
                                                                        </button>
                                                                    </div>
                                                                );
                                                            })()}
                                                        </div>
                                                    );
                                                }}
                                            </ConnectButton.Custom>
                                        </div>
                                        {
                                            show &&
                                            <div className="py-[1rem]">
                                                <p onClick={handelBonus} className="cursor-pointer text-[#DDD] underline text-center font-[Inter] text-[13px] font-[500] leading-[7px]">Do you want to stake your $scorp tokens?</p>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>

        </div >
    )
}
export default ProductDetailsSection;