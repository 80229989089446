import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { MoralisProvider } from "react-moralis";
import "./i18n";

import loader from "./assects/images/loader.gif"
import '@rainbow-me/rainbowkit/styles.css';
import { connectorsForWallets } from '@rainbow-me/rainbowkit';


import { configureChains, createConfig, WagmiConfig } from 'wagmi';

import { mainnet, bsc } from 'wagmi/chains';
// import { alchemyProvider } from 'wagmi/providers/alchemy';
// import { publicProvider } from 'wagmi/providers/public';

import { Theme } from '@rainbow-me/rainbowkit';
// import { trustWallet, coinbaseWallet, walletConnectWallet, metaMaskWallet, rainbowWallet } from '@rainbow-me/rainbowkit/wallets';


import {
  getDefaultConfig,
  RainbowKitProvider,
} from '@rainbow-me/rainbowkit';
import { WagmiProvider } from 'wagmi';
import {
  QueryClientProvider,
  QueryClient,
} from "@tanstack/react-query";

const myCustomTheme: Theme = {
  blurs: {
    modalOverlay: '...',
  },
  colors: {
    accentColor: 'linear-gradient(90deg, #AE8625 -3.12%, #F7EF8A 29.71%, #D2AC47 73.6%, #EDC967 102.14%)',
    modalBackground: '#fff',
  }
};

const projectId = 'f1fa989b52589ea5a9f742a48081a6f4';

// const { connectors } = getDefaultWallets({
//   appName: 'Scorpion Casino',
//   projectId,
//   chains
// });
// const connectors = connectorsForWallets([
//   {
//     groupName: 'Recommended',
//     wallets: [
//       metaMaskWallet({ projectId, chains }),
//       walletConnectWallet({ projectId, chains }),
//       trustWallet({ projectId, chains }),
//       coinbaseWallet({ projectId, chains }),
//     ],
//   },
// ]);

// const wagmiConfig = createConfig({
//   autoConnect: true,
//   connectors,
//   publicClient,
//   webSocketPublicClient,
// });
const config = getDefaultConfig({
  appName: 'Scorpion Casino',
  projectId: 'f1fa989b52589ea5a9f742a48081a6f4',
  chains: [bsc],
  ssr: true, // If your dApp uses server side rendering (SSR)
});
const queryClient = new QueryClient();
  
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Suspense fallback={

      <>
        <div className="bg-[#111010] ">
          <div className="h-[100vh] bg-[#0e0e0ed9] w-[100%] flex h-[100vh] space-y-2  justify-center items-center">
            <div className="mx-auto">
              <img className="m-auto h-[140px] xs:h-[115px]" src={loader} alt="" />
              <h3 className="ml-[10px] text-[36px] gradientText font-[800]">LOADING</h3>
            </div>
          </div>
        </div>

      </>
    }>
    <WagmiProvider config={config}>
      <QueryClientProvider client={queryClient}>
        <RainbowKitProvider modalSize="compact" theme={myCustomTheme}>
          <App />

          {/* <div className="w-full h-[1080px]" style={{ textAlign: 'center', backgroundImage: `url(${loadin})` }}>
          </div>
          <div class="loading">
            <img src={logo} class="loading-logo" alt="logo" /></div> */}
        </RainbowKitProvider>
      </QueryClientProvider>
    </WagmiProvider>
    </Suspense >
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
